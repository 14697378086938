<template>
  <div>
    <div class="heap">
      <h3 class="center">Зарплата по точкам детально</h3>
      <div>
        <form v-on:submit.prevent="" class="flex">
          <div class="heap-block">
            <DateSelector label="Дата нач." :val="date_begin" @change="date_begin = $event" />
          </div>
          <div class="heap-block">
            <DateSelector label="Дата кон." :val="date_end" @change="date_end = $event" />
          </div>
          <div class="heap-block high" @click.stop="switchUnit()">
            Единица отчётности
            <div class="border-round">{{ unit[unit_counter] }}</div>
            <!-- <select v-model="unit" @click="switchUnit('unit')" @change="reportDraw" ref="unit">
              <option value="rate">рубли</option>
              <option value="hour">часы</option>
            </select> -->
          </div>
          <div class="heap-block">
            <div @click.prevent="tableDataRetrieve" class="high pointer">
              <button class="btn" ref="btn">Обновить</button>
            </div>
          </div>
        </form>
      </div>

      <div class="reminder smalltext">работает сортировка по столбцам</div>

      <table class="t_table">
        <tr style="user-select: none; -webkit-user-select: none">
          <td style="width: 5%">№</td>
          <td ref="fullname" @click="sort('fullname')" class="pointer" style="width: 20%">
            ФИО
          </td>
          <!-- <td ref="job" @click="sort('job')" style="width: 25%" class="pointer">Должность</td>
            <td ref="hours" @click="sort('hours')" class="pointer">Часы</td>
            <td ref="rate" @click="sort('rate')" class="pointer">Ставка</td>
            <td ref="summary" @click="sort('summary')" class="pointer">Итого</td> -->
          <template v-for="(el, i) in pointsReport">
            <td style="word-break: break-all" :key="el">{{ pointsNicknames[i] }}</td>
          </template>
          <td ref="summary" @click="sort('summary')" class="pointer">Итого</td>
        </tr>
      </table>
    </div>

    <div v-if="loading" class="body">
      <Loader />
    </div>
    <div v-else class="body">
      <template v-if="this.total > 0">
        <table class="t_table">
          <template v-for="(row, i) in report">
            <tr :key="i">
              <td style="width: 5%">{{ i + 1 }}</td>
              <td style="text-align: left; white-space: nowrap; width: 20%">
                {{ row.fullname }}
              </td>
              <!-- <td style="width: 25%">{{ row.job }}</td>
              <td>{{ row.hours }}</td>
              <td>{{ row.rate }}</td>
              <td>{{ row.summary }}</td> -->
              <template v-for="(p, j) in pointsReport">
                <td :key="p">{{ row[j] }}</td>
              </template>
              <td>{{ row.summary }}</td>
            </tr>
          </template>
          <tr>
            <td :colspan="pointsReport.length + 2" style="text-align: left; padding-left: 8%">
              Итого
            </td>
            <td>
              <b>{{ this.total }}</b>
            </td>
          </tr>
        </table>
      </template>

      <template v-if="this.total < 0">
        <table class="t_table">
          <tr>
            <td colspan="3">нет данных по выбранным параметрам</td>
          </tr>
        </table>
      </template>
    </div>
    <br />
    <br />
  </div>
</template>

<script>
import DateSelector from "@/components/DateSelector.vue";
// import PointSelector from "@/components/PointSelector.vue";
import Loader from "@/components/Loader.vue";
import { dateFormatJS } from "@/components-js/dateFormat";
import { DATE_MAX_INTERVAL } from "@/config/settings";
import { request } from "@/components-js/requestSrv";
import { lS } from "@/components-js/localStorage";
const loStorage = new lS();

export default {
  components: { DateSelector, Loader },

  data() {
    return {
      loading: false,
      date_begin: null,
      date_end: null,
      DATE_MAX_INTERVAL,
      id_point: 0,
      report: [],
      total: 0,
      x: 1,
      pointsNicknames: [],
      pointsReport: [],
      peopleReport: [],
      unit: ["hour", "rate"],
      unit_counter: 0,
      data: [],
    };
  },
  beforeCreate() {},

  async mounted() {
    // set default values for dates
    // salary specific - it counts for halfmonths - first half and second half
    // so we need intervals 1 - 15 and 16 - last month day
    const d = new Date();
    let nowDay = d.getDate();
    let nowMonth = d.getMonth();
    let nowYear = d.getFullYear();
    if (nowDay > 16) {
      // need to create 1 - 15 interval
      this.date_begin = new Date(d.setDate(1));
      this.date_end = new Date(d.setDate(15));
    } else {
      this.date_begin = new Date(nowYear, nowMonth - 1, 16);
      this.date_end = new Date(nowYear, nowMonth - 1, new Date(nowYear, nowMonth, 0).getDate());
    }
    this.date_begin = dateFormatJS(this.date_begin);
    this.date_end = dateFormatJS(this.date_end);

    let tmp = loStorage.getObjectProp(this.$route.path, "date_begin");
    if (tmp) this.date_begin = tmp;

    tmp = loStorage.getObjectProp(this.$route.path, "date_end");
    if (tmp) this.date_end = tmp;

    this.tableDataRetrieve();
  },

  methods: {
    switchUnit() {
      console.log(this.unit_counter);
      this.unit_counter++;
      if (this.unit_counter == this.unit.length) this.unit_counter = 0;
      this.reportDraw();
    },

    async tableDataRetrieve() {
      this.loading = true;

      // check if they are dates?
      if (!new Date(this.date_begin) || !new Date(this.date_end)) return;

      // check dates for begin lower, then end
      if (new Date(this.date_begin) > new Date(this.date_end)) this.date_end = this.date_begin;

      // check for interval is not more, than date_max_interval days
      if (
        new Date(
          new Date(this.date_begin).setDate(
            new Date(this.date_begin).getDate() + this.DATE_MAX_INTERVAL
          )
        ) < new Date(this.date_end)
      )
        this.date_end = dateFormatJS(
          new Date(
            new Date(this.date_begin).setDate(
              new Date(this.date_begin).getDate() + this.DATE_MAX_INTERVAL
            )
          )
        );

      // DB request
      this.data = await request(
        "/api/report/salarybypandp",
        "POST",
        {
          date_begin: this.date_begin,
          date_end: this.date_end,
        },
        this.$route.path
      );
      this.loading = false;

      // reorganize dataset to work array

      // get points
      this.pointsReport = [];
      this.pointsNicknames = [];
      this.data.forEach((el) => {
        if (!this.pointsReport.includes(el.id_point)) {
          this.pointsReport.push(el.id_point);
          this.pointsNicknames.push(el.nickname);
        }
      });

      // get people

      this.data.forEach((el) =>
        this.peopleReport.includes(el.id_people) ? null : this.peopleReport.push(el.id_people)
      );
      this.reportDraw();
    },

    reportDraw() {
      // reset and fill final report
      let peopleCurrent = -1;
      let n = -1;

      this.total = 0;
      let temp = [];
      // form result array
      for (const el of this.data) {
        if (peopleCurrent != el.id_people) {
          peopleCurrent = el.id_people;
          n++;
          temp.push([]);
          temp[n].id_people = el.id_people;
          temp[n].summary = 0;
        }

        temp[n]["fullname"] = el.name;

        switch (this.unit_counter) {
          case this.unit.indexOf("rate"):
            temp[n][this.pointsReport.indexOf(el.id_point)] = el.money;
            temp[n].summary += Number.parseInt(el.money);
            this.total += Number.parseInt(el.money);
            break;
          case this.unit.indexOf("hour"):
            temp[n][this.pointsReport.indexOf(el.id_point)] = el.hours;
            temp[n].summary += Number.parseInt(el.hours);
            this.total += Number.parseInt(el.hours);
            break;
        }
      }

      this.report = temp;

      if (!this.total) this.total = -1;
    },

    focus(ref) {
      this.$refs[ref].focus();
    },

    sort(col) {
      if (!this.report.length) return;
      this.x *= -1;
      if (col) {
        this.report.sort((a, b) => {
          if (isNaN(parseInt(a[col])) || isNaN(parseInt(b[col]))) {
            // text sort
            if (a[col] < b[col]) return -1 * this.x;
            if (a[col] > b[col]) return 1 * this.x;
            return 0;
          } else {
            // digital sort
            if (parseInt(a[col]) < parseInt(b[col])) return -1 * this.x;
            if (parseInt(a[col]) > parseInt(b[col])) return 1 * this.x;
            return 0;
          }
        });

        for (let name in this.$refs) {
          this.$refs[name].style.fontWeight = "normal";
        }
        this.$refs[col].style.fontWeight = "bold";
      }
    },
  },
};
</script>
